export async function generateOtp(calculationId) {
    const {data} = await window.axios.post('/insurance-products/generate-otp/' + calculationId);
    return data;
}

export async function verifyOtp(calculationId, otp) {
    const {data} =  await window.axios.post('/insurance-products/verify-otp/' + calculationId, {otp})

    return data;
}

export async function generateRegistrationOtp(phoneNumber) {
    const {data} = await window.axios.post('/auth/otp-generate', {
        phone_number: phoneNumber
    });
    return data;
}

export async function verifyRegistrationOtp(phoneNumber, pin) {
    const { data } = await window.axios.post('/auth/otp-verify', {phone_number: phoneNumber, code: pin})
}

export async function generateUnsubscribeOtp(param, id) {
    const { data } = await window.axios.get(`/verification/generate-otp/${param}/${id}`)
}

export async function verifyUnsubscribeOtp(param, id, code) {
    const { data } = await window.axios.get(`/verification/verefy-otp/${param}/${id}?otp=${code}`)
}